<template>
  <div class="container" ref="box">
    <div ref="header" class="header pageHeader" style="padding-bottom: 20px;">
      <a-page-header title="用户管理"> </a-page-header>
    </div>
    <!-- <div ref="form" class="form">
      <a-form :form="form" layout="inline" ref="form">
        <a-form-item>
          <a-input
            v-decorator="['orderId', { rules: [{ required: false }] }]"
            placeholder="订单号"
          />
        <a-form-item>
          <a-button type="primary" html-type="submit" @click="getSearch">
            搜索
          </a-button>
        </a-form-item>
      </a-form>
      <a-button
        class="buttonImg"
        v-if="pageRights.export"
        @click="handleExport"
      >
        <img src="../../assets/export.png" alt="" />
        导出
      </a-button>
    </div> -->
    <a-table
      :columns="columns"
      size="middle"
      :scroll="{ x: 700, y: table_h }"
      :pagination="false"
      :data-source="list"
      :row-key="(record) => record.id"
    >
    <div slot="baby_info" slot-scope="baby_info">
      <span v-if="baby_info">
        姓名：{{baby_info.baby_name}}
        <a-icon type="woman"  v-if="baby_info.baby_sex==2" style="color:rgba(253,105,150)"/><a-icon type="man"  v-if="baby_info.baby_sex==1" style="color:rgba(253,105,150)"/>
        <br>
        生日：{{baby_info.baby_birthday}}<br>
        <!-- 性别：{{baby_info.baby_sex==1?"男":"女"}} -->
      </span>
      <span v-else>
        预产期：{{baby_info.expected_date_childbirth}}
      </span>
    </div>
      <!-- <span slot="btns" slot-scope="btns, num">
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell(num, 'finish')"
          v-if="
            num.status == 1 && pageRights.complete && num.orderType != 'local'
          "
        >
          完成订单
        </a-button>
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell(num, 'cancel')"
          v-if="
            (num.status == 0 || num.status == 1) &&
            pageRights.cancel &&
            num.orderType != 'local'
          "
        >
          取消订单
        </a-button>
      </span> -->
    </a-table>
    <div ref="pagination" style="overflow:hidden">
      <a-pagination
        v-if="pageshow"
        :default-current="currentPage"
        :defaultPageSize="defaultPageSize"
        :page-size-options="pageSizeOptions"
        show-size-changer
        :total="total_num"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
        :item-render="itemRender"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "User",
  data() {
    return {
      columns: [
        {
          title: "淘宝会员ID",
          key: "tb_special_id",
          dataIndex: "tb_special_id",
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          dataIndex: "mobile",
          align: "center",
        },
        {
          title: "宝宝信息",
          key: "baby_info",
          dataIndex: "baby_info",
          scopedSlots: { customRender: "baby_info" },
          align: "center",
        },
        {
          title: "微信群号",
          key: "wx_group",
          dataIndex: "wx_group",
          align: "center",
        },
      ],
      table_h: "",
      list: [],
      pageshow: true,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: this.$store.state.defaultPageSize,
      pageSizeOptions: this.$store.state.pageSizeOptions,
    };
  },
  watch: {
    list: {
      handler(val, val1) {
        if (val != val1) {
          this.changeTableH();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        // this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      this.$refs.box.style.display = "flex";
      if (this.table_h <= 250) {
        this.table_h = 400;
        this.$refs.box.style.display = "block";
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.getList(that.search);
    },
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      this.defaultPageSize = pageSize;
      this.$store.commit("changedefaultPageSize", pageSize);
      window.localStorage.setItem("defaultPageSize", pageSize);
      this.getSearch();
    },
    //获取列表
    getSearch() {
      var that = this;
      that.currentPage = 1;
      // that.form.validateFields((error, values) => {
      //   delete values.indate;

      //   for (var key in values) {
      //     if (!values[key]) {
      //       delete values[key];
      //     }
      //   }
      //   values.createStartTime = that.createStartTime + " 00:00:00";
      //   values.createEndTime = that.createEndTime + " 23:59:59";
      //   that.search = values;
        that.getList({});
      // });
    },
    getList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/api/admin/user/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var list = res.data.resultObject.data;
            
            that.list = list;
            that.total_num = res.data.resultObject.hasMore
              ? res.data.resultObject.current_page * data.limit + 1
              : res.data.resultObject.current_page * data.limit;
            that.total_num = that.total_num || 0;
            that.currentPage = res.data.resultObject.current_page;
          }
        });
      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
  },
};
</script>
<style scoped>
/deep/.pageHeader .ant-page-header,
/deep/.pageHeader .ant-page-header-heading {
  height: auto;
  border: 0 !important;
}
/deep/.ant-form-item {
  margin-right: 10px;
}

/deep/.ant-table-body::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
/deep/.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f2f5;
}
/deep/.ant-pagination .ant-pagination-item,
/deep/.ant-pagination .ant-pagination-jump-prev,
/deep/.ant-pagination .ant-pagination-jump-next {
  display: none;
}
.ant-btn {
  height: 36px;
  border-color: #f8f8f8;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #666;
  border-color: rgba(253,105,150);
}
form .ant-btn:hover,
form .ant-btn:focus {
  color: #fff;
}
.ant-table .ant-btn {
  height: 30px;
  padding: 0 20px;
}
</style>